<template>
  <div
    class="z-button"
    :class="[type]"
  >
    <div class="z-button-content">
      <div :class="{'z-button-text':!!icon}">
        <slot />
      </div>
      <div
        class="z-button-icon"
        v-show="icon"
      >
        <i :class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
@hollowColor: #4978fa;
.z-button {
  padding: 15px 60px;
  background: linear-gradient(180deg, #7698fd 0%, #4374fa 100%);
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  // 禁止文字选中
  user-select: none;
  &:hover {
    animation: 0.1s ease-in forwards linearGradient;
  }

  // 内容
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-text {
    margin-right: 10px;
  }

  // 圆形
  &.circle {
    border-radius: 24px;
    &.inset {
      background: #577af3;
      box-shadow: inset 0px 0px 0px #364c97, inset 0px 0px 0px #78a8ff;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
      &:hover {
        box-shadow: inset 0px 0px 20px #364c97, inset -2px -6px 20px #78a8ff;
      }
    }
  }
  // 镂空
  &.hollow {
    background: transparent;
    border: 1px solid @hollowColor;
    color: @hollowColor;
    box-shadow: inset 0 0 0px #021b6c40;
    // 点击
    &:active {
      box-shadow: inset 0 0 10px #021b6c40;
    }
    &:hover{
      animation: 0.1s ease-in forwards discoloration;
    }
  }
}

@keyframes linearGradient {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 300% 200%;
  }
}
@keyframes discoloration {
  0% {
    background: #fff;
    color: @hollowColor;
  }
  100% {
    background: @hollowColor;
    color: #fff;
  }
}
</style>
