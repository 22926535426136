<template>
  <div class="banner100vw">
    <v-banner :data="data">
      <template slot="content">
        <slot name="content" />
      </template>
      <template slot="button">
        <slot name="button" />
      </template>
    </v-banner>
  </div>
</template>

<script>
import vBanner from './index.vue'
export default {
  components: {
    vBanner
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '',
        title: '',
        content: '',
        imgUrl: ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner100vw();
</style>
