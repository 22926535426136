<template>
  <div class="banner">
    <!-- 轮播图模版 -->
    <div class="l">
      <div class="tips">
        <div class="tip">
          文锐电子交易，让交易变容易
        </div>
      </div>
      <div class="title">
        <p>{{ data.title }}</p>
      </div>
      <div class="content">
        <slot name="content">
          <span v-html="content" />
        </slot>
      </div>
      <div class="button">
        <slot name="button">
          <router-link
            class="news-list-detail-link"
            :to="{
              path: '/newsCenter/newsDetail',
              query: {
                id: data.siteNewsId
              }
            }"
            v-if="data.type === 0"
          >
            <z-button
              icon="el-icon-right"
              @click.native="toDetails"
            >
              查看详情
            </z-button>
          </router-link>
          <a
            :href="data.jumpUrl"
            target="_blank"
            v-else
          >
            <z-button
              icon="el-icon-right"
              @click.native="toDetails"
            >
              查看详情
            </z-button>
          </a>
        </slot>
      </div>
    </div>
    <div class="r">
      <img :src="data.imgUrl">
    </div>
  </div>
</template>

<script>
import zButton from '@/components/button'
export default {
  components: {
    zButton
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '',
        title: '',
        content: '',
        imgUrl: ''
      })
    }
  },
  computed: {
    content () {
      // 去除item.newsContent中的img标签
      const reg = /<.*?(?:>|\/>)|[&nbsp;]/gi
      return this.data.content.replace(reg, '')
    }
  },
  methods: {
    toDetails () {
      console.log('点击id=>2', this.data.id)
    }
  }
}
</script>

<style lang="less" scoped>
@bannerHeight: 400px;
.banner {
  display: flex;
  justify-content: space-between;
  height: @bannerHeight;
  position: relative;
  padding: 40px 0;
  box-sizing: border-box;
}
.l {
  flex: 1;
  padding-top: 30px;
  .tips {
    margin-bottom: 30px;
    .tip {
      display: inline-block;
      padding: 0 25px;
      // width: 111px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      background: #e0efff;
      border-radius: 18px 18px 18px 0px;
      font-size: 14px;
    }
  }
  .title {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    & > p {
      font-size: 24px;
      color: #333;
      letter-spacing: 2px;
    }
  }
  .content {
    padding-top: 20px;
    padding-right: 10px;
    margin-bottom: 30px;
    .fontHideNowarp(11);
    span {
      font-size: 16px;
      font-weight: 300;
      color: #666;
      width: 480px;
      line-height: 20px;
      p:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
.r {
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
</style>
